<template>
	<div class="dropzone">

		<input type="file" class="absolute inset-0 w-full opacity-0 z-10 cursor-pointer" :accept="accept" @change="change" v-if="canSelectFile">
		
		<div class="text-xs text-blue-400" v-if="!file">
			Click here to add file.
		</div>
		<div class="text-xs text-blue-400" v-else-if="uploading">
			Uploading: <span class="font-black">{{ fileName }}</span>
		</div>
		<div class="text-xs text-blue-400 font-black" v-else>
			{{ fileName }}
		</div>

		<div
			class="absolute w-6 h-6 bg-red-100 text-red-500 text-xs rounded-full cursor-pointer right-0 mr-5 flex justify-center items-center"
			@click.prevent="cancel"
			v-if="canCancel"
		>
			✕
		</div>

		<div
			class="absolute w-6 h-6 bg-blue-500 text-white text-xs rounded-full cursor-pointer left-0 ml-5 flex justify-center items-center"
			v-if="file && uploaded"
		>
			✓
		</div>

		<div class="flex absolute left-0 bottom-0 right-0 h-1 rounded-full overflow-hidden mb-4 ml-5 mr-5" v-if="canShowProgress">
			<div class="d-inline h-full bg-blue-500 duration-300" :style="{width: `${uploadProgress}%`}"></div>
		</div>

	</div>
</template>

<script>
	export default {
		model: {
			prop: 'value',
			event: 'change'
		},
		props: {
			accept: {
				type: String,
				default: null
			},
			value: {
				type: File,
				default: null
			},
			uploading: {
				type: Boolean,
				default: false
			},
			uploaded: {
				type: Boolean,
				default: false
			},
			uploadProgress: {
				type: Number,
				default: 0
			},
			failed: {
				type: Boolean,
				default: false
			},
			cancelUpload: {
				type: Function,
				default: null
			}
		},
		computed: {
			canCancel() {
				return this.file;
			},
			canSelectFile() {
				return !this.file;
			},
			canShowProgress() {
				return this.file && this.uploading || this.uploaded;
			},
			fileName() {
				return this.$options.filters.truncate(this.file?.name, 30);
			},
		},
		data() {
			return {
				file: null
			}
		},
		mounted() {
			this.file = this.value;
		},
		watch: {
			value(data) {
				this.file = data;
			}
		},
		methods: {
			cancel() {
				if (this.uploading && this.cancelUpload) {
					this.cancelUpload();
				}else {
					this.file = null;
					this.$emit('change', this.file);
				}
			},
			change(event) {
				this.file = event.target.files[0];
				this.$emit('change', this.file);
			}
		}
	}
</script>